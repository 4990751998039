import React from 'react'

const Footer = () => {
  return (
    <div className="w-90  pb2">
      <h2 className="f3-l white pa1 ml3 mb0 fw3 inter">Join #office-tech to help build things like this &hearts;</h2>
    </div>
  )
}

export default Footer