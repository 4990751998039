import React from 'react';
import Weather from './components/Weather';
import Transport from './components/Transport';
// import Carousel from './components/Carousel';
import Greeting from './components/Greeting';
import Lunch from './components/Lunch';
import Footer from './components/Footer';
import Breakfast from './components/Breakfast';
import Kudos from './components/Kudos';
import FromEmma from './components/FromEmma';
import Logo from './assets/jigsaw_white.png';
import './App.css';
import Clock from 'react-clock';
import 'react-clock/dist/Clock.css';
import { readSync } from 'fs';
import { render } from '@testing-library/react';



class App extends React.Component {
  constructor(){
    super()
      this.state = {
        date: new Date(),
      }
    }
  
  // componentDidMount() {
  //   setInterval(
  //     () => this.setState({ date: new Date() }),
  //     1000
  //   );
  // }

  render() {
    return (
      <div className="App flex flex-wrap flex-column justify-around items-center align-center  vh-100 pa1">
        {/* <Carousel /> */}
        <div className="logo">
          <img src={Logo} className="logo" alt="logo"/>
        </div>
        {/* <Greeting className="f-headline-l inter f2-ns" name="[Name from facial recognition]"/> */}
        <div className="widgets-container justify-around flex flex-wrap ma1 w-100"> 
          <Transport className="ma3"/>
          <FromEmma className="ma3"/>
          <Weather/>
          <Lunch coming="this" vendor="Mon An by City Caphe" notes="" className="ma3" />
          <Kudos className="ma3" />
          <Breakfast coming="this" vendor="The Med" notes="" className="ma3" />
        </div>
        <Footer className="fixed bottom-1"/>
      </div>
    )
  }
}

export default App;
