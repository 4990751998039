import React, {Component}  from 'react';

class Transport extends Component {
  constructor(props){
    super(props)
    this.state ={
      lineStatuses: [{}],
    }
  let requestURL = 'https://api.tfl.gov.uk/Line/northern/Status?detail=true';
  let request = new XMLHttpRequest();
  request.open('GET', requestURL);
  request.responseType = 'json';
  request.send();
  request.onload = (function() {

    const response = request.response;
    console.log(response)
    this.setState(response[0])

  }).bind(this)
}

render(){
    return (
      <div className="w-40 pb2">
        <h2 className="f1-l white pa1 ml3 mb0 fw3 inter">Northern Line</h2>
        <h2 className="f1-l mid-gray pa1 ml3 mt0 fw3 inter">{this.state.lineStatuses[0].statusSeverityDescription}</h2>
      </div>
    );
  }
}

export default Transport;
