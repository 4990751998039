import React from 'react'

const Breakfast = (props) => {
  return (
    <div className="w-40  pb2">
      <h2 className="f1-l white pa1 ml3 mb0 fw3 inter">Breakfast {props.coming} week</h2>
      <h2 className="f1-l mid-gray pa1 ml3 mv0 fw3 inter">{props.vendor}</h2>
      <p className="f4-l mid-gray pa1 ml3 mt0 fw3 inter">{props.notes}</p>
    </div>
  )
}

export default Breakfast