import React from 'react';

export default class Weather extends React.Component {
  constructor() {
    super();
    this.state = {};
    this.loadWeather()
  }

  async loadWeather() {
    try {
      const res = await fetch('https://tnr83oh1wi.execute-api.eu-west-1.amazonaws.com/v1/weather');
      const weather = res.json();
      this.setState({ weather });
    } catch (err) {
      console.error(err);
    } 
    // finally {
    //   setInterval(this.loadWeather.bind(this), (1000 * 60 * 2));
    // }
  }

  render() {
    return (
      <div className="w-40 pb5">
      <h2 className="f1-l white pa1 ml3 mb0 fw3 inter">The Weather</h2>
      <h2 className="f1-l mid-gray pa1 ml3 mt0 fw3 inter">[Coming soon]</h2>
      </div>
    );
  }
};
