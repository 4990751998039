import React from 'react';

class Kudos extends React.Component {

  constructor() {
    super()
    this.state = {
      slackMessages: {}
    }
    this.loadSlackMessages()
  }

  async loadSlackMessages() {
    try {
      const slackToken = "xoxp-83986891826-400569521922-897720850944-0eb4a00971ef40c515dcd89b075f6d7e"
      const conversationId = "C6XMF4UNP"
      const res = await fetch(`https://slack.com/api/conversations.history?token=${slackToken}&channel=${conversationId}`)
      const slackMessages = await res.json();
      this.setState({ 
          slackMessages: slackMessages.messages[0]
       })
    } catch (err) {
      console.error(err);
    } 
  }


  render() {
    return (
      <div className="w-40 pb2">
        <h2 className="f1-l white pa1 ml3 mb0 fw3 inter">Latest Kudos</h2>
        <h2 className="f1-l mid-gray pa1 ml3 mt0 fw3 inter">{this.state.slackMessages.text}</h2>
      </div>
    );
  }
};

export default Kudos