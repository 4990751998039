import React from 'react';

class FromEmma extends React.Component {

  constructor() {
    super()
    this.state = {
      emmaSays: {}
    };
    this.loadSlackMessages()
  }

  async loadSlackMessages() {
    try {
      const slackToken = "xoxp-83986891826-400569521922-897720850944-0eb4a00971ef40c515dcd89b075f6d7e"
      const conversationId = "CSFNRKQ6T"
      const res = await fetch(`https://slack.com/api/conversations.history?token=${slackToken}&channel=${conversationId}`)
      const emmaSays = await res.json();
      console.log(emmaSays.messages[0])
      this.setState({ 
          emmaSays: emmaSays.messages[0]
       })
    } catch (err) {
      console.error(err);
    } 
    //   finally {
    //   setInterval(this.loadSlackMessages.bind(this), (100 * 60 * 2));
    // }
  }


  render() {
    return (
      <div className="w-40 pb2 inter">
        <h2 className="f1-l white pa1 ml3 mb0 fw3 inter">A note from Emma</h2>
        <h2 className="f1-l mid-gray pa1 ml3 mt0 fw3 inter">{this.state.emmaSays.text}</h2>
      </div>
    );
  }
};

export default FromEmma